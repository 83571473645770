import { Autocomplete, FormControl, TextField } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import _ from 'lodash';

interface IProps {
  useForm: UseFormReturn;
  options: any;
  multiple?: boolean;
  limitTags?: number;
  nameField: string;
  disabled?: boolean;
  isFieldDiff?: string;
}
export const AutoCompleteField = (props: IProps) => {
  const {
    useForm,
    options,
    multiple,
    limitTags,
    nameField,
    disabled,
    isFieldDiff,
  } = props;
  const {
    control,
    formState: { errors },
  } = useForm;

  return (
    <FormControl margin="dense">
      <Controller
        name={nameField}
        control={control}
        render={({ field }) => (
          <Autocomplete
            key={!multiple && field?.value}
            multiple={multiple}
            options={
              multiple
                ? _.differenceBy(options, field.value, isFieldDiff)
                : options
            }
            size="small"
            filterSelectedOptions
            limitTags={limitTags ? limitTags : 2}
            getOptionLabel={option => option?.name}
            renderOption={(props, option) => <li {...props}>{option?.name}</li>}
            value={field.value}
            onChange={(e, newValue) => {
              field.onChange(newValue);
            }}
            disabled={disabled}
            renderInput={params => (
              <TextField
                {...params}
                error={!!errors[nameField]}
                helperText={errors[nameField]?.message}
              />
            )}
          />
        )}
      />
    </FormControl>
  );
};
